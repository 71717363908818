:root {
	/* colors */
	--primary: rgb(101, 180, 187);
	--white: rgb(255, 255, 255);
	--background: rgb(225, 216, 199);
	--secondary: rgb(240, 224, 125);
	--dark-secondary: rgb(227, 171, 70);
	--dark: rgb(86, 67, 45);

	/* default sizing */
	--lg-container: 1000px;
	--sm-container: 400px;
	--header-height: 60px;
}

*,
*:before,
*:after {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	font-size: 16px;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

a {
	text-decoration: none;
	color: inherit;
}

br {
	color: inherit;
	background-color: var(--dark);
	border-color: var(--dark);
	border-style: solid;
	border-width: 2px;
	display: block;
}
